import { Box, List } from '@mui/material'
import { FooterLink } from 'PublicWebApi/Settings/types'
import Logo from 'apps/Site/components/Logo'
import {
  ContactLinksColumnWrapper,
  LogoLinkWrapper,
} from './ContactLinksColumn.styles'
import ContactLinks from './ContactLinks'

interface ContactLinksProps {
  links: FooterLink[]
}

const ContactLinksColumn = ({ links }: ContactLinksProps) => {
  const socialLinks = links?.slice(0, 3)
  const loginAndAboutLinks = links?.slice(3, 5)
  const listOfLinks = [socialLinks, loginAndAboutLinks]

  return (
    <ContactLinksColumnWrapper data-testid="links-column-wrapper">
      <LogoLinkWrapper
        href="/privatperson"
        aria-label="Startsida TRR.se"
        data-testid="footer-logo"
      >
        <Logo />
      </LogoLinkWrapper>
      <List disablePadding data-testid={'footer-contactlinkslist'}>
        {listOfLinks?.map(
          (links, index) =>
            links?.length > 0 && (
              <Box
                pb={2}
                key={`contactlinks-${index.toString()}`}
                data-testid={'footer-contactlinks-wrapper'}
              >
                <ContactLinks links={links} />
              </Box>
            )
        )}
      </List>
    </ContactLinksColumnWrapper>
  )
}

export default ContactLinksColumn
