import { menuSlice } from './Menus'
import { deviceSlice } from './Device'
import { cookiesSlice } from './Cookies'
import { authSlice } from './Auth'
import { gtmLogsSlice } from './gtmLogsSlice'
import { visitorContextSlice } from './VisitorContext'
import { mobileLeftMenuSlice } from './mobileLeftMenu'
import { combineSlices } from '@reduxjs/toolkit'

const combinedSliceReducers = combineSlices(
  cookiesSlice,
  deviceSlice,
  menuSlice,
  mobileLeftMenuSlice,
  authSlice,
  gtmLogsSlice,
  visitorContextSlice
)

export default combinedSliceReducers
