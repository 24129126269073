import { createContext, useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { AppBar, Box, IconButton, Toolbar, styled } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import SearchIcon from '@mui/icons-material/Search'

import Logo from 'apps/Site/components/Logo'
import RightMenu from 'apps/Site/Header/RightMenu'
import { useTrackCustomClick } from 'features/Telemetry/GoogleTagManager/Hooks/useTrackCustomClick'
import { useAuthentication } from 'features/Authentication/Hooks'
import { smallDeviceMenuStatus } from 'slices/Menus/types'
import { useSliceDispatch, useSliceStateSelector } from 'slices/hooks'
import { useCachedSettingsState } from 'PublicWebApi'

import Menu from '../Menu'
import { LogoLinkWrapper } from '../../NewHeader.styles'
import { getLoggedInContext, getLoginUrl } from '../../NewHeader.hooks'
import AvatarButton from '../../AvatarButton'
import { cookieName, getCookieValue } from 'utils/cookies'
import NewLeftMenu from 'apps/Site/LoggedInNavigation/NewLeftMenu'
import { mobileLeftMenuStatus } from 'slices/mobileLeftMenu/types'

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'sticky',
  backgroundColor: theme.palette.background.default,
  width: '100vw',
  boxShadow: 'none',
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  width: 'inherit',
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(2)}`,
  },
}))

const renderAvatarLoggedInButton = (
  visitorContext: string,
  onClick?: () => void
) => {
  return (
    <AvatarButton
      isMobile
      visitorContext={visitorContext}
      onClickMobileAction={onClick}
    />
  )
}

interface SubMenuContextProps {
  isSubmenuOpen: boolean
  setIsSubmenuOpen: (state: boolean) => void
  submenuTitle?: string | null
  setSubmenuTitle?: (title: string) => void
}

export const SubMenuContext = createContext<SubMenuContextProps>({
  setIsSubmenuOpen: null,
  isSubmenuOpen: false,
  submenuTitle: null,
  setSubmenuTitle: null,
})

const Navbar = () => {
  const menuContent = useCachedSettingsState().megaNavigation?.contexts
  const { isLoggedIn, profile } = useAuthentication()
  const trigger = useSliceDispatch()
  const trackCustomClick = useTrackCustomClick()
  const currentLocation = useLocation()
  const [openMenu, setOpenMenu] = useState(false)
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const submenuContext = useMemo(
    () => ({ isSubmenuOpen, setIsSubmenuOpen }),
    [isSubmenuOpen, setIsSubmenuOpen]
  )
  const { visitorContextStatus } = useSliceStateSelector(
    (state) => state.slices.visitorContexts
  )
  const { smallDeviceMenuStatus } = useSliceStateSelector(
    (state) => state.slices.menus
  )
  const useNewLoggedInMenu = Boolean(
    getCookieValue(cookieName.newNavigationSystemCookie)
  )

  const userRole = profile.idp

  const showTRRMenu = useCallback(
    (type: smallDeviceMenuStatus) => {
      if (type === smallDeviceMenuStatus || type === 'none') {
        trigger('setSmallDeviceMenuStatus', 'none')
      } else {
        trigger('setSmallDeviceMenuStatus', type)
      }
    },
    [smallDeviceMenuStatus, trigger]
  )

  const showNewTRRMenu = useCallback(
    (toggleMenu: mobileLeftMenuStatus) => {
      trigger('setMobileLeftMenuStatus', toggleMenu)
    },
    [trigger]
  )

  const onClickMenuButton = () => {
    if (
      profile.idp === 'local' &&
      currentLocation.pathname.includes('/mitt-trr')
    ) {
      if (useNewLoggedInMenu) {
        showNewTRRMenu('open')
      } else {
        showTRRMenu('right')
      }
    }
    setOpenMenu(!openMenu)
    setIsSubmenuOpen(false)

    if (useNewLoggedInMenu) {
      showNewTRRMenu('closed')
    } else {
      showTRRMenu('none')
    }
  }

  const onClickAvatarButton = () => {
    if (useNewLoggedInMenu) {
      showNewTRRMenu('open')
    } else {
      showTRRMenu('right')
    }
  }

  const closeAllMenus = () => {
    setOpenMenu(false)
    setIsSubmenuOpen(false)

    if (useNewLoggedInMenu) {
      showNewTRRMenu('closed')
    } else {
      showTRRMenu('none')
    }
  }

  const onSearchButtonClick = () => {
    trackCustomClick('Menu action button', {
      label: `Sök`,
      href: '/sok',
    })
    closeAllMenus()
  }

  return (
    <>
      <StyledAppBar>
        <StyledToolbar>
          <LogoLinkWrapper
            href="/privatperson"
            aria-label="Startsida TRR.se"
            onClick={closeAllMenus}
          >
            <Logo data-testid="mobile-trr-logo" />
          </LogoLinkWrapper>
          <Box display="flex" gap={1}>
            {!openMenu && (
              <>
                <IconButton
                  data-testid="navbar-search-button"
                  href="/sok/"
                  onClick={onSearchButtonClick}
                >
                  <SearchIcon />
                </IconButton>
                {isLoggedIn ? (
                  renderAvatarLoggedInButton(
                    getLoggedInContext(userRole, visitorContextStatus),
                    () => onClickAvatarButton()
                  )
                ) : (
                  <IconButton
                    href={getLoginUrl('/login', visitorContextStatus as string)}
                    data-testid="header-avatar-button"
                    onClick={() => {
                      trackCustomClick('Menu action button', {
                        label: 'Logga in',
                        href: getLoginUrl(
                          '/login',
                          visitorContextStatus as string
                        ),
                      })
                    }}
                  >
                    <AccountCircleIcon data-testid="AccountCircleIcon-navbar" />
                  </IconButton>
                )}
                <IconButton
                  data-testid="open-menu-button"
                  onClick={onClickMenuButton}
                  data-gtm-label="open-menu-button"
                >
                  <MenuRoundedIcon />
                </IconButton>
              </>
            )}
          </Box>
        </StyledToolbar>
      </StyledAppBar>
      <SubMenuContext.Provider value={submenuContext}>
        <Menu
          data-testid="submenu"
          menuContent={menuContent}
          openMenu={openMenu}
          onClickMenuButton={onClickMenuButton}
        />
        {useNewLoggedInMenu ? (
          <NewLeftMenu />
        ) : (
          <RightMenu isNewHeader testSelector="new-header-mobile-right-menu" />
        )}
      </SubMenuContext.Provider>
    </>
  )
}

export default Navbar
