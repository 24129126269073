import ArrowForward from '@mui/icons-material/ArrowForward'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'

import { Helmet } from 'react-helmet-async'

import {
  createImageAltTextFromFileName,
  getFullImageUrl,
  getStandardSrcset,
} from '@trr/internal-helpers'
import { useCachedSettingsState } from 'state/PublicWebApi'
import { handledHttpErrors } from 'state/PublicWebApi/Settings'
import { HTMLMapper } from '@trr/html-mapper'

export const ContentErrorPage = ({
  statusCode,
}: {
  statusCode: handledHttpErrors
}) => {
  const { MEDIA_URL } = window.APP_CONFIGURATION.COMMON

  const httpErrorContent =
    useCachedSettingsState().errorPageSettings[statusCode]
  return (
    <>
      <Helmet>
        <meta
          name="prerender-status-code"
          content={`${httpErrorContent.errorCode}`}
        />
      </Helmet>
      <Box
        data-testid={`errorPage${httpErrorContent.errorCode}`}
        sx={{
          flexGrow: 1,
          mt: { xs: 5, md: 10 },
          mb: 5,
          maxWidth: '1140px',
          mx: 'auto',
        }}
      >
        <Grid container columns={20}>
          <Grid item xs={20} md={10}>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              Felkod {httpErrorContent.errorCode}
            </Typography>
            <Typography
              sx={{ typography: { xs: 'h3', md: 'h1' } }}
              component={'h1'}
              mb={4}
            >
              {httpErrorContent.heading}
            </Typography>
            <Box mb={4}>
              <HTMLMapper
                mediaUrl={window.APP_CONFIGURATION.COMMON.MEDIA_URL}
                body={httpErrorContent.ingress}
              />
            </Box>
            <Stack
              justifyContent={'space-between'}
              flexDirection={{ xs: 'column', md: 'row' }}
              gap={2}
            >
              {httpErrorContent.buttonUrl && httpErrorContent.buttonText && (
                <Button
                  data-gtm-label="content-error-link"
                  href={httpErrorContent.buttonUrl}
                  endIcon={<ArrowForward />}
                >
                  {httpErrorContent.buttonText}
                </Button>
              )}
              {httpErrorContent.secondaryButtonUrl &&
                httpErrorContent.secondaryButtonText && (
                  <Button
                    variant="outlined"
                    data-gtm-label="content-error-link"
                    href={httpErrorContent.secondaryButtonUrl}
                    endIcon={<ArrowForward />}
                  >
                    {httpErrorContent.secondaryButtonText}
                  </Button>
                )}
            </Stack>
          </Grid>
          {httpErrorContent.image && (
            <Grid item md={10} sx={{ display: { xs: 'none', md: 'block' } }}>
              <img
                src={getFullImageUrl(MEDIA_URL, httpErrorContent.image, 680)}
                srcSet={getStandardSrcset(MEDIA_URL, httpErrorContent.image, [
                  768,
                ])}
                alt={createImageAltTextFromFileName(httpErrorContent.image)}
                loading="lazy"
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}

export default ContentErrorPage
