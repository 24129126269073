import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { mobileLeftMenuStatus, MobileLeftMenuState } from './types'

const initialState: MobileLeftMenuState = {
  mobileLeftMenuStatus: 'closed',
}

const contextSlice = createSlice({
  name: 'mobileLeftMenuStatus',
  initialState,
  reducers: {
    setMobileLeftMenuStatus: (
      state,
      action: PayloadAction<mobileLeftMenuStatus>
    ): MobileLeftMenuState => ({
      ...state,
      mobileLeftMenuStatus: action.payload,
    }),
  },
})

export default contextSlice
