import { Box, styled } from '@mui/material'
import { cookieName, getCookieValue } from 'utils/cookies'

const RowBreakWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}))

const RowBreakLine = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.divider,
  height: '1px',
  width: '100%',
}))

const RowBreak = () => {
  const useNewLoggedInNavigationSystem = Boolean(
    getCookieValue(cookieName.newNavigationSystemCookie)
  )

  return (
    <RowBreakWrapper
      sx={{
        paddingY: useNewLoggedInNavigationSystem ? 0 : 1,
        marginY: useNewLoggedInNavigationSystem ? 3 : 0,
      }}
    >
      <RowBreakLine data-testid="row-break-block" />
    </RowBreakWrapper>
  )
}

export default RowBreak
