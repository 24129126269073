import { useEffect, useRef, useState } from 'react'
import { isNil, isEmpty, either, pipe, not } from 'ramda'
import { Box, styled, Typography } from '@mui/material'

import { TableOfContentsLink } from './TableOfContentsLink'
import { TableOfContentLink } from 'state/PublicWebApi/Content'

interface TableOfContentsProps {
  items: TableOfContentLink[]
  title?: string
}

const onlyUnique = (
  value: TableOfContentLink,
  index: number,
  arr: TableOfContentLink[]
) => arr.findIndex((t) => t.headingId === value.headingId) === index

const shouldRender = pipe<TableOfContentLink[], boolean, boolean>(
  either(isNil, isEmpty),
  not
)

export const TableOfContentsBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    top: '80px',
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(5),
    position: 'sticky',
  },
}))

const NavWrapper = styled('nav')(({ theme }) => ({
  marginBottom: theme.spacing(7),
  [theme.breakpoints.up('lg')]: {
    maxWidth: '370px',
  },
}))

const ListWrapper = styled('ol')(({ theme }) => ({
  padding: theme.spacing(1.5, 1.5, 1.5, 0),
  margin: 0,
  listStyle: 'none',
  [theme.breakpoints.down('lg')]: {
    padding: 0,
    margin: theme.spacing(1.5, 1.5, 1.5, 0),
  },
}))

const useScrollSpy = (ids: string[], options: IntersectionObserverInit) => {
  const [activeId, setActiveId] = useState<string>()
  const observer = useRef<IntersectionObserver>()
  useEffect(() => {
    const sections = ids.map((id) => document.getElementById(id))
    observer.current?.disconnect()
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.id)
        }
      })
    }, options)
    sections.forEach((section) => {
      if (section) {
        observer.current?.observe(section)
      }
    })
    return () => observer.current?.disconnect()
  }, [ids, options])
  return activeId
}

export const TableOfContents = ({
  items,
  title = 'Innehåll på denna sida',
}: TableOfContentsProps) => {
  const headingIds =
    shouldRender(items) && items.map(({ headingId }) => headingId)

  const activeId = useScrollSpy(headingIds || [], {
    rootMargin: '0% 0% -70% 0%',
    threshold: 0.5,
  })

  return shouldRender(items) ? (
    <NavWrapper>
      <Typography pl={2} variant="subtitle2">
        {title}
      </Typography>
      <ListWrapper>
        {items.filter(onlyUnique).map((item) => (
          <TableOfContentsLink
            key={`toclink-${item.headingId}`}
            item={item}
            isActive={activeId === item.headingId}
          />
        ))}
      </ListWrapper>
    </NavWrapper>
  ) : (
    <></>
  )
}
