import { Box, Stack } from '@mui/material'
import { ScrollWrapper } from 'apps/Site/Epi/ScrollWrapper'
import { Introduction } from 'apps/Site/Epi/Blocks'

import CollectionPageBlockMapper, {
  CollectionPageBlock,
} from './CollectionPageBlockMapper/CollectionPageBlockMapper'
import { IContentBlock } from '../../BlockMapper/BlockMapper'

export interface CollectionPageProps {
  heading: string
  text: string
  startHereArea: IContentBlock[]
  blockArea: CollectionPageBlock[]
}

const Collection = ({
  heading,
  text,
  startHereArea,
  blockArea,
}: CollectionPageProps) => {
  return (
    <ScrollWrapper>
      <Introduction content={{ heading: heading, text: text }} />
      <Box mb={{ xs: 7, md: 10 }}>
        {startHereArea?.length > 0 && <Box></Box>}
      </Box>
      <Stack
        direction="column"
        spacing={{ xs: 7, md: 10 }}
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {blockArea?.length > 0 &&
          CollectionPageBlockMapper({ blocks: blockArea })}
      </Stack>
    </ScrollWrapper>
  )
}

export default Collection
