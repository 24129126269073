import { FontIcon } from '@trr/internal-helpers'
import { Box, Card, CardActionArea, styled, Typography } from '@mui/material'

export interface ToolsAndServicesCardBlockProps {
  properties: {
    heading: string
    text: string
    icon: string
    sourcePage: string
  }
}

const CardWrapper = styled(Card)(({ theme }) => ({
  minWidth: '20%',
  maxWidth: '288px',
  flexGrow: 1,
  height: '100%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    maxWidth: '100%',
  },
}))

const IconAndHeadingWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

const IconWrapper = styled(Box)(({ theme }) => ({
  width: '40px',
  height: '40px',
  borderRadius: '64px',
  backgroundColor: theme.palette.info?.light,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const ToolsAndServicesCard = ({
  properties: { heading, text, icon, sourcePage },
}: ToolsAndServicesCardBlockProps) => {
  return (
    <CardWrapper data-testid="tas-cardwrapper">
      <CardActionArea
        data-testid="tas-cardactionarea"
        sx={{ padding: 3, height: '100%' }}
        href={sourcePage}
      >
        <IconAndHeadingWrapper>
          <IconWrapper data-testid="tas-icon">
            <FontIcon customColor="info.dark" iconName={icon} />
          </IconWrapper>

          <Typography
            sx={{ marginLeft: { xs: 2, md: 0 }, marginTop: { xs: 0, md: 2 } }}
            variant="h6"
            component="h3"
            data-testid="tas-heading"
          >
            {heading}
          </Typography>
        </IconAndHeadingWrapper>

        <Typography
          mt={1.5}
          variant="body1"
          color="text.secondary"
          data-testid="tas-text"
        >
          {text}
        </Typography>
      </CardActionArea>
    </CardWrapper>
  )
}

export default ToolsAndServicesCard
