import { alpha, Box, Drawer, styled } from '@mui/material'
import { useSliceDispatch, useSliceStateSelector } from 'slices/hooks'
import NewMenuArea from './NewMenuArea/NewMenuArea'

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  marginRight: theme.spacing(12),
  '& .MuiDrawer-paper': {
    borderRadius: '0px 16px 16px 0px',
    width: 'inherit',
    border: 'none',
  },
}))

const DrawerWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.04),
  height: '100%',
  overflowY: 'auto',
}))

const NewLeftMenu = () => {
  const { isMobile, isTablet } = useSliceStateSelector(
    (state) => state.slices.device.deviceSize
  )

  const { mobileLeftMenuStatus } = useSliceStateSelector(
    (state) => state.slices.mobileLeftMenuStatus
  )

  const trigger = useSliceDispatch()
  const openMobileMenu = mobileLeftMenuStatus === 'open'

  const closeDrawer = () => {
    trigger('setMobileLeftMenuStatus', 'closed')
  }

  const isMobileOrTablet = isMobile || isTablet

  return (
    <StyledDrawer
      sx={{ width: isMobileOrTablet ? '344px' : '288px' }}
      anchor="left"
      open={!isMobileOrTablet ? true : openMobileMenu}
      onClose={closeDrawer}
      variant={isMobileOrTablet ? 'temporary' : 'persistent'}
      data-testid={openMobileMenu ? 'new-left-menu-mobile' : 'new-left-menu'}
    >
      <DrawerWrapper>
        <NewMenuArea isMobileOrTablet={isMobileOrTablet} />
      </DrawerWrapper>
    </StyledDrawer>
  )
}

export default NewLeftMenu
