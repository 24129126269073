import { Box, Grid2 as Grid, Typography } from '@mui/material'
import ToolsAndServicesCard, {
  ToolsAndServicesCardBlockProps,
} from 'apps/Site/components/ToolsAndServicesCard/ToolsAndServicesCard'

export interface ToolsAndServicesBlockProps {
  content: {
    heading: string
    text: string
    blockArea: ToolsAndServicesCardBlockProps[]
  }
}

const ToolsAndServicesBlock = ({
  content: { heading, text, blockArea },
}: ToolsAndServicesBlockProps) => {
  return (
    blockArea &&
    blockArea.length > 0 && (
      <Box
        data-testid="tools-and-services-block"
        display="flex"
        width="100%"
        flexDirection="column"
      >
        <Box maxWidth={'800px'}>
          <Typography variant="h3" component="h2" pb={1}>
            {heading}
          </Typography>
          {text && (
            <Typography variant="body1" color="text.secondary">
              {text}
            </Typography>
          )}
        </Box>
        <Grid mt={3} container spacing={2} data-testid="blockarea-grid">
          {blockArea.map((block) => (
            <Grid
              size={{ xs: 12, md: 3 }}
              key={`TaS-grid-${block.properties.heading}`}
              data-testid="blockarea-griditem"
            >
              <ToolsAndServicesCard properties={block.properties} />
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  )
}

export default ToolsAndServicesBlock
